import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from "next-auth/react"

// https://fontawesome.com/docs/web/use-with/react/use-with#getting-font-awesome-css-to-work
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

// https://nextjs.org/docs/basic-features/layouts

import { ReactElement, ReactNode, useEffect } from 'react'
import type { NextPage } from 'next'
import { Analytics } from '@vercel/analytics/react'
import { ThemeProvider } from 'next-themes'
import TagManager from 'react-gtm-module';

// pages/_app.js
import { Inter } from "next/font/google";

// TODO: run to upgrade: npx @next/codemod@latest built-in-next-font .
// If loading a variable font, you don't need to specify the font weight
const inter = Inter({ subsets: ['latin'] })

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID || 'GTM-XXXXX' });
}, []);

  // To add a custom font
  // <div className={inter.className}>
  // </div>
  return (
    // <ThemeProvider attribute="class">
      <SessionProvider session={session}>
        {getLayout(<Component {...pageProps} />)}
        <Analytics />
      </SessionProvider>
    // </ThemeProvider>
  )
}